<script lang="ts">
    import { fade, fly, slide } from "svelte/transition";
    import { go } from "../stores/storeRoute";
    import IMask from 'imask';
    import { onMount } from "svelte";
    import storeForm, {saveStoreForm, USER_NAME_DEFAULT} from "../stores/storeForm";
    import Loader from "../components/Loader.svelte";
    import SvgArrowLeft from "../components/SVGArrowLeft.svelte";

    const next = () => {
        go("form4");
    }

    let isLoading = false
    let isErrorPhone = false

    let valueName = ''
    let valuePhone = ''

    let mask = IMask(document.createElement('input'), {mask: ''})

    onMount(() => {
        const element = document.getElementById('phone')!;
        const maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        mask = IMask(element, maskOptions);

        valueName = $storeForm.name
        mask.unmaskedValue = $storeForm.phone
        valuePhone = mask.unmaskedValue
    })

    const focusPhone = () => {
        setTimeout(() => {
            const element = (document.getElementById('phone') as HTMLInputElement);
            if (element.value === '') element.value = '+7 ('
        }, 1)
    }

    const onInput = (e: any) => {
        setTimeout(() => {
            valuePhone = mask.unmaskedValue
            if (validatePhone(valuePhone)) {
                document.getElementById('name')?.focus()
            }
        }, 1)
    }

    const validatePhone = (valuePhone: string) => {
        return valuePhone.length === 11
    }

    const onSubmit = async () => {
        $storeForm.name = valueName || USER_NAME_DEFAULT
        $storeForm.phone = valuePhone

        saveStoreForm()

        isLoading = true
        let resp

        try {
            resp = await fetch(import.meta.env.VITE_BACKEND_URL + '/api/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify($storeForm)
            })

            const body = await resp.json()
            if (body.success)  {
                next()
                return
            }
        } catch {

        }

        go('form4error')
    }

    const onBack = () => {
        go('form2')
    }

    $: product = $storeForm.product
    $: canSubmit = validatePhone(valuePhone)
</script>

<div
    in:fly={{
        x: window.innerWidth + 20,
        duration: 200,
        opacity: 1,
        delay: 200,
    }}
    out:fly={{ x: -window.innerWidth - 20, duration: 200, opacity: 1 }}
>
    <div class="h-[200px] flex justify-center relative">
        <div class="aspect-square h-full bg-theme-100 flex flex-col justify-center items-center p-2 rounded-lg">
            <img src="/assets/img/products/{product}.webp" alt={product} />
        </div>

        <div class="absolute w-8 h-8 bg-[#93b7d9] rounded-full p-2 left-[20px] top-[83px] cursor-pointer" on:click={onBack} >
            <SvgArrowLeft/>
        </div>
    </div>
    <div>
        <div class="mt-6">
            <div class="text-xl font-bold mb-2">Ваш телефон</div>
            <label
                class="input input-bordered flex items-center gap-2 bg-theme-100"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    class="w-4 h-4 opacity-70"
                >
                    <path
                        fill-rule="evenodd"
                        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                    />
                </svg>
                <input
                    id="phone"
                    type="text"
                    class="grow"
                    placeholder="+7 (999) 999 99-99"
                    on:focus={focusPhone}
                    on:input={onInput}
                />
            </label>
            {#if isErrorPhone}
                <div transition:slide class="text-sm text-danger">
                    Введите номер телефона
                </div>
            {/if}
        </div>

        <div class="mt-6">
            <div class="text-xl font-bold mb-2">Ваше имя</div>
            <label
                class="input input-bordered flex items-center gap-2 bg-theme-100"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="w-4 h-4 opacity-70"
                >
                    <path
                        d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"
                    />
                </svg>
                <input
                    id="name"
                    type="text"
                    class="grow"
                    placeholder="Аноним"
                    bind:value={valueName}
                />
            </label>
        </div>
        <button
            class="bg-call w-full rounded-lg p-2 mt-8 text-white font-bold disabled:bg-gray-400 disabled:text-gray-700"
            disabled={!canSubmit}
            on:click={onSubmit}>Заказать звонок</button
        >
        <a
            class="flex items-center justify-center bg-call w-full rounded-lg p-2 mt-2 text-white font-bold disabled:bg-gray-400 disabled:text-gray-700"
            href="tel:{import.meta.env.VITE_PHONE}">Позвонить</a
        >
    </div>

    {#if isLoading}
        <div
            transition:fade
            class="absolute w-full h-full left-0 top-0 bg-theme-300 flex justify-center items-center"
        >
            <Loader />
        </div>
    {/if}
</div>
