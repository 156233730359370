<script>
    import { go } from "../stores/storeRoute";
    const goHome = () => go('form1')
    const phone = import.meta.env.VITE_PHONE
</script>
<div class="fixed bg-theme-200 w-full flex px-2 items-center shadow z-10">
    <div class="w-12 h-12 cursor-pointer" on:click={goHome}>
        <img src="/assets/img/logo.webp" alt="МосГорЗаправка логотип" />
    </div>
    <div class="flex flex-col ml-2 -mt-1 cursor-pointer" on:click={goHome}>
        <span class="font-bold text-lg">МосГорЗаправка</span>
        <span class="text-xs -mt-2">Работаем с 1997 года</span>
    </div>
    <div class="flex-1"></div>

    <a class="flex justify-center items-center h-full bg-call rounded-full" href="tel:{phone}">

        <div class="w-10 h-10 flex justify-center items-center">
            <img class="w-[50%]" src="/assets/img/phone.webp" alt="phone">
        </div>
    </a>
</div>
