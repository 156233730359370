
import { writable } from 'svelte/store';

type StoreRoute = 'form1' | 'form2' | 'form3' | 'form4' | 'form4error'

const storeRoute = writable<StoreRoute>('form1')

export default storeRoute

export const go = (path: StoreRoute) => {
    storeRoute.set(path);
}
