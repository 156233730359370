<script lang="ts">
    import { fade, fly } from "svelte/transition";
    import { go } from "../stores/storeRoute";
    import storeForm from "../stores/storeForm";
    import { AVAILABLE_PRODUCTS } from "../lib/tables";
    import SvgArrowLeft from "../components/SVGArrowLeft.svelte";

    const products = AVAILABLE_PRODUCTS.slice(0, AVAILABLE_PRODUCTS.length-1);


    const next = () => {
        go("form3");
    }

    const onChoice = (product: string) => {
        $storeForm.product = product
        next()
    }

    const onBack = () => {
        go('form1')
    }


</script>

<div in:fade={{ delay: 300 }} out:fly={{ x: -window.innerWidth - 20, duration: 200, opacity: 1 }}>
    <span class="text-xl font-bold">Какой у вас принтер (МФУ)?</span>

    <div class="absolute w-8 h-8 bg-[#93b7d9] rounded-full p-2 left-[20px] top-[83px] cursor-pointer" on:click={onBack}>
        <SvgArrowLeft/>
    </div>

    <div class="grid w-full mt-2 grid-cols-2 gap-2">
        {#each products as product}
            <button on:click={() => onChoice(product)} class="aspect-square bg-theme-100 flex flex-col justify-center items-center p-2 rounded-lg shadow shadow-hover cursor-pointer">
                <img src="/assets/img/products/{product}.webp" alt={product} />
            </button>
        {/each}
        <button on:click={() => onChoice('others')} class="bg-theme-100 flex flex-col justify-between items-center p-2 rounded-lg shadow shadow-hover cursor-pointer" style="grid-column: 1 / -1">
            <img class="h-[130px]" src="/assets/img/products/others.webp" alt='other printers' />
            <span class="font-bold text-sm text-secondary">Мы заправляем все монохромные и цветные картриджи!</span>
            <span class="font-bold text-lg text-theme-400">Другой производитель или другая услуга</span>
        </button>
    </div>
</div>
