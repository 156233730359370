
import {get, writable} from 'svelte/store';

type StoreForm = {
    product: string,
    phone: string,
    name: string
}

export const USER_NAME_DEFAULT = 'Аноним'

const storeFormDefault =(): StoreForm => ({
    product: '',
    phone: '',
    name: '',
})

const storeForm = writable<StoreForm>(storeFormDefault())

export const saveStoreForm = () => {
    localStorage.setItem('storeForm', JSON.stringify(get(storeForm)))
}

export const loadStoreForm = () => {
    const dump = localStorage.getItem('storeForm')
    if (!dump) return

    let obj
    try {
        obj = JSON.parse(dump)
    } catch {
        return
    }
    const value = storeFormDefault()
    if (obj.phone)
        value.phone = obj.phone
    if (obj.name && obj.name !== USER_NAME_DEFAULT)
        value.name = obj.name

    storeForm.set(value)
}

export default storeForm
