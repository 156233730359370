<script lang="ts">
  import Form_1 from "./routes/Form_1.svelte";
  import Form_2 from "./routes/Form_2.svelte";
  import Form_3 from "./routes/Form_3.svelte";
  import Form_4 from "./routes/Form_4.svelte";
  import Form_4_error from "./routes/Form_4_error.svelte";
  import Navbar from "./routes/Navbar.svelte";
  import storeRoute from "./stores/storeRoute";

  $: route = $storeRoute;
</script>

<Navbar />

<main class="pt-[60px] px-2 max-w-sm m-auto">
  {#if route === "form1"}
    <Form_1 />
  {:else if route === "form2"}
    <Form_2 />
  {:else if route === "form3"}
    <Form_3 />
  {:else if route === "form4"}
    <Form_4 />
  {:else if route === "form4error"}
    <Form_4_error />
  <!-- {:else if route === "policy"}
    <Policy /> -->
  {/if}
  <div class="mt-4"></div>
</main>

<style>
</style>
