<script lang="ts">
    import { fly } from "svelte/transition";
    import { go } from "../stores/storeRoute";
    import { onMount } from "svelte";
    import storeForm from "../stores/storeForm";

    const toHome = () => {
        go('form1')
    }
</script>

<div in:fly={{ x: window.innerWidth + 20, duration: 200, opacity: 1, delay: 200 }} out:fly={{ x: -window.innerWidth - 20, duration: 200, opacity: 1 }}>
    <div class="flex flex-col w-full justify-center items-center mt-5">
        <button class="aspect-square w-[80%] bg-theme-100 rounded-full flex flex-col justify-center items-center bg-girl">
        </button>
        <span class="text-2xl font-bold mt-5">Спасибо!</span>
        <span>Мы перезвоним через 5 минут</span>
        <button class="bg-call w-full rounded-lg p-2 mt-3 text-white font-bold" on:click={toHome}>На главную</button>
    </div>
</div>

<style>
.bg-girl {
    background-image: url("/assets/img/girl-happy.webp");
    background-size: cover;
    background-position: center;
}
</style>
