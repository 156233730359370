import './css/font-inter.css'
import './app.css'
import App from './App.svelte'
import { preloadImage } from './lib/utils'
import { AVAILABLE_PRODUCTS } from './lib/tables'


AVAILABLE_PRODUCTS.forEach((el) => {
  preloadImage(`/assets/products/${el}.webp`)
})
preloadImage('/assets/img/girl-happy.webp')
preloadImage('/assets/img/fixer.webp')


const app = new App({
  target: document.getElementById('app')!,
})

export default app
