<script lang="ts">
    import { fly } from "svelte/transition";
    import { go } from "../stores/storeRoute";
    import { onMount } from "svelte";
    import storeForm from "../stores/storeForm";

    const toHome = () => {
        go('form1')
    }
    const phone = import.meta.env.VITE_PHONE
</script>

<div in:fly={{ x: window.innerWidth + 20, duration: 200, opacity: 1, delay: 200 }} out:fly={{ x: -window.innerWidth - 20, duration: 200, opacity: 1 }}>
    <div class="flex flex-col w-full justify-center items-center mt-5">
        <button class="aspect-square w-[80%] bg-theme-100 rounded-full flex flex-col justify-center items-center bg-fixer">
        </button>
        <span class="text-2xl font-bold mt-5">Ошибка...</span>
        <span>Простите, сервер временно недоступен. Мы уже решаем эту проблему.</span>
        <span>Мы готовы принять ваш заказ по телефону. Нажмите на кнопку, чтобы связаться с оператором.</span>
        <a href="tel:{phone}" class="aspect-square w-[30%] bg-call shadow shadow-hover rounded-full flex flex-col justify-center items-center mt-3">
            <img class="w-[50%]" src="/assets/img/phone.webp" alt="phone">
        </a>
        <button class="bg-theme-100 w-full rounded-lg p-2 mt-3 text-black font-bold" on:click={toHome}>На главную</button>
    </div>
</div>

<style>
.bg-fixer {
    background-image: url("/assets/img/fixer.webp");
    background-size: cover;
    background-position: center;
}
</style>
